import React from "react"

export default function TileListItem(props) {
  return (
    <li>
      <a class="tile-link" href={props.url} target="_blank" rel="noreferrer">
        <span class="tile-meta">{props.meta}</span>
        <h3 class="tile-title">{props.title}</h3>
        <span class="tile-subtitle">{props.subtitle}</span>
        <span class="tile-meta">{props.meta2}</span>
      </a>
    </li>
  )
}
