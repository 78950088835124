import React from "react"
import Layout from "../components/layout" // highlight-line
import BoxQuote from "../components/box-quote"
import { Helmet } from "react-helmet"
import scrollTo from 'gatsby-plugin-smoothscroll';
import { withPrefix } from "gatsby"
import SiteHeader from "../components/site-header"
import SiteNav from "../components/site-nav"
import LinkedCover from "../components/linked-cover"
import BuyButtons from "../components/buy-buttons"
import URLBooktopia from "../components/url-booktopia"
import URLAmazonEbook from "../components/url-amazon-ebook"
import TileListItem from "../components/tile-list-item"
import "../styles/nanoreset.min.css"
import "../styles/style.css"


export default function Home() {
  return (
    <>
      <Helmet>
        <meta charset="utf-8" />
      	<meta name="viewport" content="width=device-width, initial-scale=1.0, viewport-fit=cover" />
        <title>Glimpses of Utopia · Jess Scully</title>
        <link rel="stylesheet" href="https://stackpath.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css" />
        <script src="https://js.createsend1.com/javascript/copypastesubscribeformlogic.js" type="text/javascript" />
        <meta name="twitter:card" content="summary"></meta>
        <meta name="twitter:creator" content="@jessaroo" />
        <meta property="og:title" content="Glimpses of Utopia &middot; Jess Scully" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://glimpsesofutopia.com" />
        <meta property="og:description" content="The people, movements and ideas shaping a better world." />
        <meta property="og:image" content="https://glimpsesofutopia.com/images/social.png" />
        <meta property="og:image:width" content="1200" />
        <meta property="og:image:height" content="630" />
        <meta name="description" content="The people, movements and ideas shaping a better world."/>
        <link rel="canonical" href="https://glimpsesofutopia.com/" />
        <link rel="icon" type="image/png" href={withPrefix('images/favicon.png')} />
        <link rel="apple-touch-icon" sizes="180x180" href={withPrefix('images/apple-touch-icon.png')} />
      </Helmet>

      <Layout> {/* highlight-line */}
        <SiteHeader />
        <SiteNav />

      	<a id="about"></a>

      	<section class="box first">
      		<h2 class="large">
      			You don’t wake up and find a utopia fully realised and perfectly formed. They happen a little bit at a time, unevenly, erratically, and if we know how to look, we can see glimpses of them, everywhere.
      		</h2>

      		<div class="box-columns">
      			<div class="box-column box-column-skinny box-column-first">
      				<div class="sticky-contents">
                <LinkedCover />
                <BuyButtons />
      				</div>
      			</div>
      			<div class="box-column box-column-wide box-column-last body-text">
      					<p>
      						It’s hard to be excited about the future right now. Climate change is accelerating; inequality is growing; politics is polarised; institutions designed to protect us are strained; technology is disrupting the world of work. We need to upgrade the operating systems of our society.
      					</p>
      					<p>
      						Jess Scully asks, <em>What can we do?</em> The answer is: plenty! All over the world, people are refusing the business-as-usual mindset and putting humans back into the civic equation, reimagining work and care, finance and government, urban planning and communication, to make them better and fairer for all.
      					</p>
      					<p>
      						Meet the care workers reclaiming control in India and Lebanon, the people turning slums into safe havens in Kenya and Bangladesh, and champions of people-powered digital democracy in Iceland and Taiwan. There are radical bankers funding renewable energy in the USA and architects redesigning real estate in Australia, new payment systems in Italy and the Philippines that keep money in local communities, and innovators redesigning taxation to cut pollution and incentivise creative solutions.
      					</p>
      					<p>
      						<em>Glimpses of Utopia</em> is a call for optimism. Humans everywhere are rising up to confront our challenges with creativity, resilience and compassion. Harnessing technology and imagination, we can reshape our world to be fair and sustainable. This book shows us how.
      					</p>
      					<p><em>Glimpses of Utopia</em> is published by <a href="https://www.panterapress.com.au/">Pantera Press</a>; order the <a href={URLBooktopia()}>paperback</a> or the <a href={URLAmazonEbook()}>eBook</a> now.</p>
      			</div>
      		</div>
      	</section>

        <BoxQuote>
          <blockquote>
            “Our task is to reach out into the future and to pull it into the present, because we have run out of time. In <em>Glimpses of Utopia</em> Jess Scully directs us towards the kind of compelling vision we need, in the understanding that we are living in an imperfect&nbsp;world.”
          </blockquote>
          <figcaption>
            Christiana Figueres, <em>The Future We Choose</em>
          </figcaption>
        </BoxQuote>

      	<a id="author"></a>

      	<section class="box">
      		<div class="box-columns">
      			<div class="box-column box-column-first box-column-wide body-text">
      				<h2 class="large">
                Jess Scully is a curator who uses creativity to engage people in the knowledge economy and with urban life in the twenty-first century, working to shape a sustainable and inclusive&nbsp;future.
      				</h2>
              <p>
              In&nbsp;2019 she was elected as Deputy Lord Mayor of the City of Sydney  and in 2016 she was elected as a Councillor, as a member of the Clover Moore Independent Team.
              </p>
      				<p>
                Jess has charted a unique career over twenty years; founding ground-breaking festival Vivid Ideas, Australia’s largest creative industries event; supporting emerging talent through projects such as the Qantas Spirit of Youth Awards; sparking new thinking through TEDxSydney and transforming public space as a public art curator. She has also worked as a policy advisor, radio host and magazine editor.
      				</p>
      				<p>
      					As a Councillor, Jess advocates for new models to address the housing crisis and support workforces of the future, is working on reviving nightlife and expanding access to culture, and protecting digital rights in the public realm. She is committed to opening up politics to younger and more diverse people, to expand who plays a role in shaping the life of the city.
      				</p>
              <p>
                <em>Glimpses of Utopia</em> is her first book.
              </p>
      			</div>
      			<div class="box-column box-column-last box-column-skinny">
      				<div class="sticky-contents">
      					<img src="/images/js-headshot.jpg" alt="" />
      					<p class="caption">
      						Photo by <a href="https://www.waltermaurice.com/">Walter Maurice</a>
      					</p>
      				</div>
      			</div>
      		</div>
      	</section>

        <BoxQuote>
          <blockquote>
            “Jess Scully has scoured the world for the best ideas to fix this mess we’re in. I found myself scribbling in the margins of each page, nodding enthusiastically and talking about what I’d just read with whomever would&nbsp;listen.”
          </blockquote>
          <figcaption>
            Jess Hill, <em>See What You Made Me Do</em>
          </figcaption>
        </BoxQuote>

      	<a id="events"></a>

      	<section class="box">
      		<h2 class="large">
      			Events & Talks
      		</h2>
      		<div class="body-text">
      			<p>
              Upcoming and recent <em>Glimpses</em> talks and events:
      			</p>
      			<ul class="tile-list">
              <TileListItem
                meta="November 6"
                title="TEDxSydney"
                subtitle="Speaker"
                url="https://tedxsydney.com/contributor/jess-scully-2/"
              />
              <TileListItem
                meta="September 24, 7.30pm AEST"
                title="Bell Debates"
                subtitle="with Benjamin Law, Jan Fran, Jane Caro AM, Miriam Corowa, & Jonathan Biggins"
                url="https://www.bellshakespeare.com.au/whats-on/digital/bell-debates/"
                meta2="Livestream"
              />
              <TileListItem
                meta="September 5"
                title="Room to Dream"
                subtitle="with Mary Hoang"
                url="https://bwf.org.au/online-events/room-to-dream/jess-scully-and-mary-hoang"
                meta2="Brisbane Writers’ Festival"
              />
              <TileListItem
                meta="August 26, 2pm AEST"
                title="Committee for Sydney LIVE"
                subtitle="with Gabriel Metcalf"
                url="https://sydney.org.au/committee-for-sydney-live-event/webinars/glimpses-of-utopia-real-ideas-for-a-fairer-world-with-jess-scully-deputy-lord-mayor-of-sydney/"
                meta2="Webinar"
              />
              <TileListItem
                meta="August 15, 4pm AEST"
                title="A Brighter Future"
                subtitle="with Damon Gameau"
                url="https://tickets.mwf.com.au/Events/A-Brighter-Future"
                meta2="Melbourne Writers Festival"
              />
              <TileListItem
                meta="August 12, 7pm AEST"
                title="Booktopia LIVE"
                subtitle="Jess Scully in conversation with Sally Rugg"
                url="https://www.facebook.com/Booktopia/videos/2665219270392929"
                meta2="Booktopia’s Facebook Page"
              />
              <TileListItem
                meta="August 6, 7pm AEST"
                title="Author Talk: Glimpses of Utopia"
                subtitle="Jess Scully in conversation with Benjamin Law"
                url="https://www.sl.nsw.gov.au/whats-on/events-online/author-talks/author-talk-glimpses-utopia"
                meta2="State Library of NSW"
              />
      			</ul>
      		</div>
      	</section>

        <BoxQuote>
          <blockquote>
            “Remarkably invigorating – a collection of eminently practical ideas, from a writer who has done the nitty gritty work of making change herself. And if we were able to join together to make them happen, our world would change. As we emerge from the pandemic, this is precisely the kind of thinking that we&nbsp;need.”
          </blockquote>
          <figcaption>
            Bill McKibben, <em>Deep Economy</em>
          </figcaption>
        </BoxQuote>

      	<a id="connect"></a>

      	<section class="box">
      		<h2 class="large">
      			Connect
      		</h2>
      		<div class="body-text">
      			<p>
              Connect with Jess on social media:
      			</p>
            <ul class="inline-list">
            <li>
      				<a href="https://twitter.com/jessaroo">
                <i class="fa fa-lg fa-twitter"></i>
                Twitter
              </a>
      			</li>
      			<li>
      				<a href="https://www.facebook.com/jessscullysydney/">
                <i class="fa fa-lg fa-facebook-official"></i>
                Facebook
              </a>
      			</li>
      			<li>
      				<a href="https://www.instagram.com/jessscullysydney/">
                <i class="fa fa-lg fa-instagram"></i>
                Instagram
              </a>
      			</li>
            </ul>

      			<p>
              Hear about events and opportunities to take action by joining our newsletter:
      			</p>

            <div>
              <form class="js-cm-form" id="subForm" action="https://www.createsend.com/t/subscribeerror?description=" method="post" data-id="92D4C54F0FEC16E5ADC2B1904DE9ED1A0587D72742299D7FA9E9ED692A3CC784A6B3C4D9A43CFD427B40FE382E57E978933F2C778D89CAD60BB7A055BD19F156">
                <div>
                  <div class="field-group">
                    <label>Name </label>
                    <input aria-label="Name" id="fieldName" maxlength="200" name="cm-name" placeholder="Your Name" />
                  </div>
                  <div class="field-group">
                    <label>Email </label>
                    <input autocomplete="Email" aria-label="Email" class="js-cm-email-input qa-input-email" id="fieldEmail" maxlength="200" name="cm-jrlyikr-jrlyikr" required="" type="email" placeholder="Your Email Address"/>
                  </div>
                </div>
                <button type="submit">Subscribe</button>
              </form>
            </div>

      		</div>
      	</section>

        <BoxQuote>
          <blockquote>
            “As a long-term social activist, I know protesting about what’s wrong is not enough! Without <em>Glimpses of Utopia</em>, creating more civil societies too often falls victim to pessimism. So we need utopian options that offer the optimism needed to create fairer futures! Jess offers proposals to discuss, explore and design our way out of the current&nbsp;mess!”
          </blockquote>
          <figcaption>
            Eva Cox AO
          </figcaption>
        </BoxQuote>

      	<a id="press"></a>

      	<section class="box">
      		<h2 class="large">
      			Press
      		</h2>
      		<div class="body-text">
      			<p>
      				Selected <em>Glimpses</em> coverage:
      			</p>
            <ul class="tile-list">
              <TileListItem
                meta="December 5, 2020"
                title="In Glimpses of Utopia, deputy mayor of Sydney Jess Scully presents inspiring ideas for social change"
                subtitle="The Canberra Times"
                url="https://www.canberratimes.com.au/story/7034412/a-rousing-call-to-action-with-utopia-in-our-sights/"
              />
              <TileListItem
                meta="September 4, 2020"
                title="Jess Scully: is this the face of Sydney's next Lord Mayor?"
                subtitle="Sydney Morning Herald"
                url="https://www.smh.com.au/politics/nsw/jess-scully-is-this-the-face-of-sydney-s-next-lord-mayor-20200829-p55qjs.html"
              />
              <TileListItem
                meta="September 3, 2020"
                title="Jess Scully is a leader with vision"
                subtitle="Dumbo Feather #64: Consciousness Rising"
                url="https://www.dumbofeather.com/articles/introducing-issue-64-consciousness-rising/"
              />
              <TileListItem
                meta="August 16, 2020"
                title="Scrapping old business models for a mutual future"
                subtitle="Co-op News"
                url="https://www.thenews.coop/149691/topic/business/scrapping-old-business-models-for-a-mutual-future/"
              />
              <TileListItem
                meta="August 12, 2020"
                title="There are new ways to do democracy that give me hope. More voices could change everything"
                subtitle="The Guardian"
                url="https://www.theguardian.com/books/2020/aug/12/there-are-new-ways-to-do-democracy-that-give-me-hope-more-voices-could-change-everything"
              />
              <TileListItem
                meta="August 6, 2020"
                title="Afternoons with James Valentine"
                subtitle="ABC Radio Sydney"
                url="https://www.abc.net.au/radio/sydney/programs/afternoons/afternoons/12512400"
              />
              <TileListItem
                meta="August 6, 2020"
                title="Glimpses of Utopia: An Extract"
                subtitle="Kill Your Darlings"
                url="https://www.killyourdarlings.com.au/article/glimpses-of-utopia-an-extract/"
              />
              <TileListItem
                meta="August 4, 2020"
                title="Jess Scully on how a better world is possible in ‘Glimpses of Utopia’"
                subtitle="The Good Reading Podcast"
                url="https://soundcloud.com/goodreadingpodcast/jess-scully"
              />
              <TileListItem
                meta="August 2, 2020"
                title="This Mortal Coil: Glimpses of&nbsp;Utopia"
                subtitle="Nightlife with Indira Naidoo, ABC&nbsp;Local&nbsp;Radio"
                url="https://www.abc.net.au/radio/programs/nightlife/this-mortal-coil-glimpses-of-utopia/12516966"
              />
              <TileListItem
                meta="August 1, 2020"
                title="Caring in mind"
                subtitle="The Saturday Paper"
                url="https://www.thesaturdaypaper.com.au/culture/books/2020/08/01/sydney-deputy-lord-mayor-jess-scully/159620400010203"
              />
              <TileListItem
                meta="July 29, 2020"
                title="Meet Jess Scully"
                subtitle="Melbourne Writers Festival"
                url="https://mwf.com.au/blog/meet-jess-scully/"
              />
              <TileListItem
                meta="July 17, 2020"
                title="Non-fiction reviews"
                subtitle="Sydney Morning Herald"
                url="https://www.smh.com.au/culture/books/non-fiction-reviews-the-art-of-pollination-and-three-more-titles-20200710-p55ave.html"
              />
      			</ul>
      		</div>
      	</section>

        <BoxQuote>
          <blockquote>
            “<em>Glimpses of Utopia</em> provided me the rarest thing in these grim times: hope. For the first time in so long, it shook me out of despondency, and made me excited and galvanised by the potential future of&nbsp;democracy.”
          </blockquote>
          <figcaption>
            Benjamin Law, writer and broadcaster
          </figcaption>
        </BoxQuote>

      	<a id="buy"></a>

      	<section class="box">
      		<h2 class="large">
      			Buy the book
      		</h2>

      		<div class="box-columns">
      			<div class="box-column box-column-skinny box-column-first">
      				<div class="sticky-contents">
                <LinkedCover />
                <BuyButtons />
      				</div>
      			</div>
      			<div class="box-column box-column-wide box-column-last body-text">
      					<p>
      						<strong>Paperback</strong><br />
                  Order online through <a href={URLBooktopia()}>Booktopia</a>, or find your <a href="https://www.loveyourbookshop.com.au/your-local-bookshop/find-a-bookshop">local independent bookseller here</a>.
      					</p>
      					<p>
      						<strong>eBook</strong><br />
                  Read <em>Glimpses</em> <a href={URLAmazonEbook()}>on your Kindle</a> or <a href="https://www.kobo.com/au/en/ebook/glimpses-of-utopia">Kobo eReader</a>. <em>Glimpses</em> is also available on <a href="https://books.apple.com/au/book/glimpses-of-utopia/id1505644637">Apple Books</a> and the <a href="https://play.google.com/store/books/details/Jess_Scully_Glimpses_of_Utopia?id=XBbaDwAAQBAJ">Google Play Store</a>.
      					</p>
      					<p>
      						<strong>Audiobook</strong><br />
                  The audiobook of <em>Glimpses</em> is out now on all major platforms including <a href="https://www.audible.com.au/pd/Glimpses-of-Utopia-Audiobook/1004022638">Audible</a>, <a href="https://books.apple.com/au/audiobook/glimpses-of-utopia/id1531753854">Apple Books</a>, and the <a href="https://play.google.com/store/audiobooks/details/Jess_Scully_Glimpses_of_Utopia?id=AQAAAEAchhfGGM">Google Play Store</a>.
      					</p>
                <p>
      						<strong>Libraries</strong><br />
                  Borrow <em>Glimpses</em> <a href="https://trove.nla.gov.au/work/239779789">from your local library</a> <em>(or request a copy if they don’t already have one!)</em>.
      					</p>
      			</div>
      		</div>
      	</section>

        <BoxQuote>
          <blockquote>
            “An audacious appeal to our best selves, daring us to reimagine a better world that not just should be but could be. <em>Glimpses</em> is the gentle nudge we need as we grapple with how to turn a global calamity into an opportunity to create a better, fairer future for ourselves, our children and our&nbsp;planet.”
          </blockquote>
          <figcaption>
            Jan Fran, writer and broadcaster
          </figcaption>
        </BoxQuote>

      	<nav class="footer-nav">
      		<ul class="inline-list">
      			<li>
      				<a href="https://twitter.com/jessaroo">
                <i class="fa fa-lg fa-twitter"></i>
                Twitter
              </a>
      			</li>
      			<li>
      				<a href="https://www.facebook.com/jessscullysydney/">
                <i class="fa fa-lg fa-facebook-official"></i>
                Facebook
              </a>
      			</li>
      			<li>
      				<a href="https://www.instagram.com/jessscullysydney/">
                <i class="fa fa-lg fa-instagram"></i>
                Instagram
              </a>
      			</li>
      			<li>
      				<a href="http://www.jessscully.com/">
                JessScully.com
              </a>
      			</li>
      			<li>
      				<a href="#top" onClick={() => scrollTo('#top')}>
                <i class="fa fa-arrow-up"></i>
                Back to top
              </a>
      			</li>
      		</ul>
      	</nav>

      	<div class="background-wrapper">
      		<div class="background"></div>
      	</div>
      </Layout> {/* highlight-line */}

    </>
  )
}
