import React from "react"

export default function Layout({ children }) {
  return (
    <>
      <a id="top" />
      <div class="contents-wrapper">
        {children}
      </div>
    </>
  )
}
