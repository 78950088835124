import React from "react"
import URLBooktopia from "../components/url-booktopia"
import URLAmazonEbook from "../components/url-amazon-ebook"
import BuyButton from "../components/buy-button"

export default function BuyButtons() {
  return (
    <>
      <ul class="book-buy-list">
        <li class="book-buy-list-label">
          Paperback
        </li>
        <BuyButton url={URLBooktopia()} format="Paperback" source="Booktopia" />
        <BuyButton url="http://www.indies.com.au/non-fiction/glimpses-of-utopia" format="Paperback" source="Indie Bookstores" />
      </ul>
      <ul class="book-buy-list">
        <li class="book-buy-list-label">
          Ebook
        </li>
        <BuyButton url={URLAmazonEbook()} format="eBook" source="Kindle" />
        <BuyButton url="https://books.apple.com/au/book/glimpses-of-utopia/id1505644637" format="eBook" source="Apple Books" />
        <BuyButton url="https://www.kobo.com/au/en/ebook/glimpses-of-utopia" format="eBook" source="Kobo" />
        <BuyButton url="https://play.google.com/store/books/details/Jess_Scully_Glimpses_of_Utopia?id=XBbaDwAAQBAJ" format="eBook" source="Google Play" />
      </ul>
      <ul class="book-buy-list">
        <li class="book-buy-list-label">
          Audiobook
        </li>
        <BuyButton url="https://www.audible.com.au/pd/Glimpses-of-Utopia-Audiobook/1004022638" format="Audiobook" source="Audible" />
        <BuyButton url="https://books.apple.com/au/audiobook/glimpses-of-utopia/id1531753854" format="Audiobook" source="Apple Books" />
        <BuyButton url="https://play.google.com/store/audiobooks/details/Jess_Scully_Glimpses_of_Utopia?id=AQAAAEAchhfGGM" format="Audiobook" source="Google Play" />
      </ul>
    </>

  )
}
