import React from "react"

export default function BuyButton(props) {
  return (
    <li>
        <a href={props.url} class={props.class} target="_blank">
        	{props.source}
        </a>
    </li>
  )
}
