import React from "react"
import { Link } from "gatsby"
import scrollTo from 'gatsby-plugin-smoothscroll';

export default function SiteNav() {
  return (
    <nav class="site-nav">
      <ul class="inline-list">
        <li>
          <Link to="/#about" onClick={() => scrollTo('#about')}>About</Link>
        </li>
        <li>
          <Link to="/#author" onClick={() => scrollTo('#author')}>Author</Link>
        </li>
        <li>
          <Link to="/#events" onClick={() => scrollTo('#events')}>Events</Link>
        </li>
        <li>
          <Link to="/#connect" onClick={() => scrollTo('#connect')}>Connect</Link>
        </li>
        <li>
          <Link to="/#press" onClick={() => scrollTo('#press')}>Press</Link>
        </li>
        <li>
          <Link to="/#buy" onClick={() => scrollTo('#buy')}>Buy</Link>
        </li>
      </ul>
    </nav>
  )
}
