import React from "react"

export default function SiteHeader() {
  return (
    <header class="site-header">
      <h1 class="visuallyhidden">Glimpses of Utopia</h1>
      <h2 class="visuallyhidden">Real Ideas for a Fairer World</h2>
      <h3 class="visuallyhidden">Jess Scully</h3>
      <img class="title-block" src="/images/glimpses-title-block.svg" alt="“Glimpses of Utopia: Real Ideas for a Fairer World”, Jess Scully" />
    </header>
  )
}
